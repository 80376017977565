import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import device from '../helpers/breakpoints';
import Layout from '../components/layout';
import Nav from '../components/nav';
import Contact from '../components/contact';
import Footer from '../components/footer';
import Anchor from '../helpers/anchor';
import CtaButton from '../helpers/buttons/cta-button';

export const query = graphql`
  {
    site {
      siteMetadata {
        name
        title
        description
      }
    }
    image: file(relativePath: { eq: "laptops-xprtz1.jpg" }) {
      publicURL
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    jasper: file(relativePath: { eq: "dit_is_jasper.png" }) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    dennis: file(relativePath: { eq: "en_ik_ben_dennis.png" }) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logo: file(relativePath: { eq: "symboltext-xprtz-white.svg" }) {
      publicURL
    }
    arrowdown: file(relativePath: { eq: "arrowdown.svg" }) {
      publicURL
    }
  }
`;

const BodyContainer = styled.div`
  max-width: 728px;
  padding: 0 1rem;
  margin: 8rem auto 4rem;

  @media ${device.tablet} {
    padding: 0 2rem;
    max-width: 960px;
  }

  @media ${device.laptop} {
    padding: 0 8rem;
    max-width: 1280px;
  }

  @media ${device.laptopL} {
    padding: 0 8rem;
    max-width: 1440px;
  }

  h1 {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.022em;
  }

  h2 {
    font-size: 26px;
    line-height: 1.18;
    letter-spacing: -0.022em;
    margin-top: 1.72em;

    + p {
      margin-top: 0.46em;
    }
  }

  p {
    font-size: 21px;
    line-height: 1.58;
    letter-spacing: -0.004em;
    margin-top: 2em;
  }
`;

const Emphasis = styled.span`
  font-weight: bold;
  margin-bottom: 0.2em;
  display: inline-block;
`;

const WerkenBijPage = ({ data }) => {
  return (
    <Layout
      metadata={{ ...data.site.siteMetadata, image: data.image.publicURL }}
    >
      <Nav Root="/" Fixed="true" />
      <BodyContainer>
        <Anchor id="banner-anchor"></Anchor>
        <h1>Werken met XPRTZ</h1>
        <p>
          <Emphasis>XPRTZ is opgericht vanuit de overtuiging dat experts met experts willen werken, en dat het leveren van expertise vanuit een duidelijk gespecialiseerde focus in een betrokken organisatievorm beter werkt.</Emphasis>
          XPRTZ bestaat uit een (h)echt team gepassioneerde .NET software ontwikkelaars. Wij zijn gedreven onze kennis continue te ontwikkelen en delen met vakgenoten. Op deze manier zijn wij in staat  klanten te adviseren over actuele mogelijkheden en kiezen we samen voor de beste oplossing.
        </p>
        <p>
          Wij geloven dat samenwerking alleen succesvol zal zijn wanneer partijen open, eerlijk en transparant naar elkaar zijn. Wij zijn en blijven een platte en intieme organisatiestructuur met korte lijnen, die zeer snel schakelen. Wie kiezen voor een persoonlijke en langdurige relatie met onze klanten om écht waarde toe te voegen aan onze expertise van zowel het individu als het team.
        </p>
        <p>
          XPRTZ kiest hierbij bewust voor een duidelijke focus op .NET en met een streng aannamebeleid, gericht op kwaliteit, niet volume realiseren wij onze kernwaarden.
        </p>
        <p>
          Is jullie organisatie op zoek naar een gespecialiseerd bedrijf dat .NET software ontwikkel uitdagingen begrijpt, op een duurzame en efficiënte manier meedenkt en samenwerkt als onderdeel van de organisatie? Dat zeggen meer organisatie maar vanuit onze kernwaarden kunnen wij dit ook echt realiseren. Wij hebben geen sales afdeling dus bij interesse, neem gerust contact met ons op, wij komen graag langs om de mogelijkheden te bespreken.
        </p>

        <CtaButton
          href="/checkup"
          css={css`
                    position: relative;
                    top: 1.5rem;


                  `}
        >
          Start de samenwerking
        </CtaButton>
        <CtaButton
          href="/trainingen"
          css={css`
                    position: relative;
                    top: 1.5rem;
                    left: 1.5rem;
                  `}
        >
          trainingen
        </CtaButton>
        <div
          css={css`
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            min-height: 250px;
            margin: 1rem auto;
            margin-top: 3rem;
          `}
        >
          <img
            src={data.jasper.sharp.fluid.src}
            alt="Dit is Jasper"
            height="250px"
          />
          <img
            src={data.dennis.sharp.fluid.src}
            alt="En ik ben Dennis"
            height="250px"
            css={css`
              margin: 0 0 0 3rem;

              @media ${device.mobileL} {
                margin: 0;
              }
            `}
          />
        </div>
      </BodyContainer>
      <Contact />
      <Footer />
    </Layout>
  );
};

export default WerkenBijPage;
